import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Nav } from "components/common"
import VideoCard from "src/components/sections/Videos/VideoCard"
import { device } from "utils"
import { Helmet } from "react-helmet"

export default function VideoTemplate({ data: { video } }) {
  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Nav />
      <Wrap>
        <VideoCard {...video} autoplay />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div`
  max-width: 1300px;
  margin: 0 auto;

  background: #f9f9f9;
  padding-top: 25vh;
  padding-left: 16px;
  padding-right: 16px;

  @media ${device.laptop} {
    padding-top: 15vh;
  }
`

export const pageQuery = graphql`
  query ($slug: String!) {
    video: contentfulVideo(slug: { eq: $slug }) {
      id
      title
      description {
        childrenMarkdownRemark {
          rawMarkdownBody
        }
      }
      youtubeUrl
    }
  }
`
